import React from 'react'
import Link from 'gatsby-link'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Layout from '../components/layout'
import * as Icon from 'react-feather'
import QuopiLogo from './../svgs/quopi-logo.svg'
import HoldBlue from './../svgs/hold-blue.svg'
import HoldRed from './../svgs/hold-red.svg'
import HoldOrange from './../svgs/hold-orange.svg'

const IndexPage = ({ location }) => {
  const appStoreURL = 'https://itunes.apple.com/app/quopi/id1439696146'
  const playStoreURL =
    'https://play.google.com/store/apps/details?id=com.quopi.quopi'

  return (
    <Layout location={location}>
      <section id="header" name="home">
        <div className="jumbotron-fluid pt-5">
          <div className="container">
            <div className="hold-blue">
              <HoldBlue />
            </div>
            <div className="hold-red">
              <HoldRed />
            </div>
            <div className="hold-orange">
              <HoldOrange />
            </div>
            <div className="logo-title">
              <QuopiLogo />
              <h2 className="display-5">
                Log and analyze your climbing workouts
              </h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-8 col-md-6 col-lg-4 offset-lg-1 mt-lg-5">
                <OutboundLink href={appStoreURL}>
                  <img
                    src={require('./../images/app-store-icon.png')}
                    className="img-fluid"
                    alt="Download Quopi in the Apple App Store"
                  />
                </OutboundLink>
                <OutboundLink href={playStoreURL}>
                  <img
                    src={require('./../images/play-store-icon.png')}
                    className="img-fluid mt-5"
                    alt="Download Quopi in the Google Play Store"
                  />
                </OutboundLink>
              </div>
              <div className="col-10 col-sm-10 col-md-6 col-lg-5 offset-lg-2 mt-5 mt-md-0">
                <img
                  src={require('./../images/phones.png')}
                  className="phones img-fluid"
                  alt="iPhones with Quopi screenshots"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" name="features">
        <div className="container mb-5">
          <div className="row">
            <div className="feature col-12 col-md-6 col-lg-5 offset-lg-1 mt-3 mt-5">
              <div className="icon">
                <Icon.Plus size="4rem" style={{ marginLeft: '-0.5rem' }} />
              </div>
              <h2>Log climbing &amp; bouldering</h2>
              <p>
                Register your climbing &amp; bouldering sessions in a few simple
                steps, whether it's on the rock, in the gym or at home. Add your
                training notes, workout location and some photos to capture the
                full experience.
              </p>
            </div>
            <div className="feature col-12 col-md-6 col-lg-5 mt-3 mt-5">
              <div className="icon">
                <Icon.BarChart2 size="4rem" style={{ marginLeft: '-0.6rem' }} />
              </div>
              <h2>Analyze your progress</h2>
              <p>
                View detailed graphs of your climbing, bouldering and exercise
                performance that help you measure your progress.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="feature col-12 col-md-6 col-lg-5 offset-lg-1 mt-3 mt-5">
              <div className="icon">
                <Icon.UserPlus size="4rem" style={{ marginLeft: '0.2rem' }} />
              </div>
              <h2>Follow your friends</h2>
              <p>
                Find friends and share your experiences. Receive feedback to
                keep pushing further.
              </p>
            </div>
            <div className="feature col-12 col-md-6 col-lg-5 mt-3 mt-5">
              <div className="icon">
                <Icon.List size="4rem" style={{ marginLeft: '-0.2rem' }} />
              </div>
              <h2>List activities</h2>
              <p>
                View your own activities and those of your friends. Get inspired
                by other people’s achievements and get motivated to become a
                better climber.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="about" name="about">
        <div className="jumbotron-fluid pt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <h2>About Quopi</h2>
                <p>
                  To become good at climbing you should just go climb often and
                  climb hard. Registering your achievements, visualize your
                  progress and be motivated by other climbers can make a huge
                  difference as well. Hence I created Quopi, a log book for
                  climbers to register your workouts and achievements.
                  Development is still ongoing, but I would love to hear your
                  feedback and know what you think.
                </p>
                <p> Happy climbing!</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="download" name="download">
        <div className="jumbotron-fluid">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <h2>Download Quopi</h2>
                <p>Quopi is available for iOS and Android.</p>
                <p>
                  I am working hard on new features and updates. Do you have
                  suggestions? Please let me know at{' '}
                  <a href="mailto:info@quopi.com">info@quopi.com</a>.
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8 col-md-5 col-lg-3">
                <OutboundLink href={appStoreURL}>
                  <img
                    className="img-fluid app-store-icon"
                    src={require('./../images/app-store-icon.png')}
                    alt="Download Quopi in the Apple App Store"
                  />
                </OutboundLink>
              </div>
              <div className="col-8 col-md-5 col-lg-3">
                <OutboundLink href={playStoreURL}>
                  <img
                    className="img-fluid app-store-icon"
                    src={require('./../images/play-store-icon.png')}
                    alt="Download Quopi in the Google Play Store"
                  />
                </OutboundLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="jumbotron-fluid footer">
        <div className="container">
          <div className="row justify-content-center links">
            <div className="col-12 col-md-4 mb-3">
              <a href="mailto:info@quopi.com">info@quopi.com</a>
            </div>
            <div className="col-12 col-md-4 mb-3">
              <Link
                style={{
                  boxShadow: 'none',
                  color: 'inherit',
                }}
                to={'/terms'}
              >
                Terms of Service
              </Link>
            </div>
            <div className="col-12 col-md-4 mb-3">
              <Link
                style={{
                  boxShadow: 'none',
                  color: 'inherit',
                }}
                to={'/privacy'}
              >
                Privacy Policy
              </Link>
            </div>
          </div>
          <div className="copyright mt-3">
            © {new Date().getFullYear()} Remco Rakers
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
